import React from 'react'

const InceLanding = (props) => (
    <section id="galtbanner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>Ince Manor <span>est. 1912</span></h1>
            </header>
            <p>
              'Ince' is a large two-and-one-half-storey Tudor style house set on a corner lot at the junction of Foul Bay Road and McNeill Avenue in Oak Bay, British Columbia.
            </p>
        </div>
    </section>
)

export default InceLanding
