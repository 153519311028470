import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import InceLanding from '../components/InceLanding'

import pic08 from '../assets/images/ince/ince-2021.jpg'
import pic09 from '../assets/images/ince/ince-2021.jpg'
import pic10 from '../assets/images/ince/ince-2021.jpg'
import pic11 from '../assets/images/ince/ince-2021.jpg'

const Ince = (props) => (
    <Layout>
        <Helmet>
            <title>Ince Manor - Victoria Heritage Apartments</title>
            <meta name="description" content="Ince Manor. Victoria British Columbia. Victoria Heritage Apartments." />
        </Helmet>

        <InceLanding />

        <div id="main">
            <section id="one" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>About</h3>
                            </header>
                            <p>Located in the lovely family-orientated neighbourhood of south Oak Bay. Ince is within walking distance to the ocean, and is a 5 minute transit commute away from downtown Victoria. Ince has had many different suite variations over the years. Currently, there are two modernly renovated 1-bedrooom basement suites. The remainder of the home is occupied by the owners. Ince's basement suites have the best of both worlds, heritage exterior and modern interior. The entirity of Ince is approximately 7,500 square feet.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Size & Scale</h3>
                            </header>
                            <p>With a total suite count of 12 units, Ince House is 3 stories high and is roughly 10,000 square feet in size.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Location</h3>
                            </header>
                            <p>Located on 2021 McNeill avenue, in the lovely family-orientated neighbourhood of Oak Bay. Ince is within walking distance to the ocean, and is a 10 minute transit commute away from downtown Victoria.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                          <header className="major">
                              <h3>Design</h3>
                          </header>
                          <p>It has a steeply-hipped roof with exposed rafter tails, many shed-roofed dormers, and one gabled dormer on the main Gillespie Pl façade. There is a large 2½-storey, half-timbered, gabled bay on the Rockland Av façade, with bracketed bargeboards and a drop finial. It has an angled bay on the main floor below an angled balcony; to the right is a large square balcony over a glassed-in main floor extension.</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Ince
